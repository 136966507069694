export const GermanTranslation = {
  nav: {
    sms: "Manuell SMS verschicken",
    history: "SMS Verlauf",
    openapi: "API Dokumentation",
    categoryUser: "Benutzereinstellungen",
    theme: 'Design',
    language: "Sprache",
    logout: "Ausloggen",
  },
  language: {
    title: "Sprache auswählen",
    intro: "Welche Sprache möchten Sie benutzen? Diese Einstellung hat keinen Einfluss auf andere Benutzer.",
    english: "Englisch",
    german: "Deutsch"
  },
  sms: {
    title: "Manuell SMS senden",
    recipient: "Nummer des Empfängers",
    recipientPlaceholder: '1761111111',
    type: 'Art der Nachricht',
    otp: 'OTP - Einmalige Kennwörter',
    arn: 'Erinnerungen und Benachrichtigungen',
    mkt: 'Marketing',
    message: 'Nachricht',
    messagePlaceholder: 'Lieber Kunde, wir erinnern Sie gerne an ihrem Termin am Montag.',
    send: 'Absenden',
    sent: 'Ihre Nachricht wurde erfolgreich versendet!',
    countryCodeDE: 'Deutschland',
    countryCodeAT: 'Österreich',
    countryCodeCH: 'Schweiz',
  },
  history: {
    title: 'Gesendete Nachrichten',
    timestamp: 'Zeitpunkt',
    recipient: 'Empfänger',
    sender: "Absender",
  },
}
