import {EnglishTranslation} from "~/lang/en";
import {GermanTranslation} from "~/lang/de";

export default defineI18nConfig(() => {
    return {
        legacy: false,
        locale: 'de',
        messages: {
            en: EnglishTranslation,
            de: GermanTranslation,
        },
    }
})
