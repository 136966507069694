export const EnglishTranslation = {
  nav: {
    sms: "Send an SMS",
    history: "History",
    openapi: "API Documentation",
    categoryUser: "User Settings",
    theme: 'Theme',
    language: "Language",
    logout: "Log out"
  },
  language: {
    title: "Choose your language",
    intro: "What language would you like to use? This setting does not affect other users.",
    english: "English",
    german: "German (Deutsch)"
  },
  sms: {
    title: "Send an SMS manually",
    recipient: "Recipient phone number",
    recipientPlaceholder: '1761111111',
    type: 'Message Type',
    otp: 'OTP - One time passwords',
    arn: 'Reminders and notifications',
    mkt: 'Marketing',
    message: 'Message',
    messagePlaceholder: 'Hi there! Just as a reminder: your appointment is this Monday.',
    send: 'Send',
    sent: 'Your SMS was successfully sent!',
    countryCodeDE: 'Germany',
    countryCodeAT: 'Austria',
    countryCodeCH: 'Switzerland',
  },
  history: {
    title: 'Sent SMS messages',
    timestamp: 'Timestamp',
    recipient: 'Recipient',
    sender: "Sender",
  },
}
